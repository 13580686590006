// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

import {IProductEnvironment} from "./FacilitiesEvaluationEnvironment";
import {FacilityAccessibilityProduct} from "../product.facility/model/FacilityAccessibilityProduct";
import {EProductType} from "../javascript.lib.mojo-base/model/ProductType";
import {FacilityAccessibilityProduct2} from "../product.facility/model/FacilityAccessibilityProduct2";
import {FacilityAccessibilityProduct3} from "../product.facility/model/FacilityAccessibilityProduct3";
import {FacilityAccessibilityProduct4} from "../product.facility/model/FacilityAccessibilityProduct4";

export const environment: IProductEnvironment = {

  name: 'Eli Lilly: Manufacturing',
  filename: 'environment.manufacturing-767689.ts',

  isDevelopment: false,
  isProduction: true,
  isTest: false,

  authenticationConfig: {

    emailLink: false,
    textMessage: true,
    usernamePassword: true
  },

  firebaseConfig: {
    apiKey: "AIzaSyDIdFSmZ9nxTJpIKa1X_UPB0hB0nvXQB0g",
    authDomain: "facilities-767689.firebaseapp.com",
    databaseURL: "https://facilities-767689-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "facilities-767689",
    storageBucket: "facilities-767689.appspot.com",
    messagingSenderId: "1017442438621",
    appId: "1:1017442438621:web:4937dd97a101e090927a34"
  },

  // curl -iLS https://us-central1-$_TARGET_ENV.cloudfunctions.net/public/functions/info
  cloudFunctionsUrl: 'https://us-central1-facilities-767689.cloudfunctions.net',

  nocoDbConfig: {
    // httpServer: 'http://localhost:8080',
    // httpServer: 'http://spot.local:8080',
    httpServer: 'https://mobilitymojo-spot.com',
    user: 'test@mobilitymojo.com',
    password: 'test@mobilitymojo.com',
    authorization: 'Basic bW9iaWxpdHk6bW9qbw==',
    // projectTitle: 'products-playpen',
    projectTitle: '2024 - Grosvenor and The Crown Estate',
  },

  productConfig: {

    firebaseAnswersRoot: '/answers-x/manufacturing',
    // product:  ProductHelper.cloneProduct( new FacilityAccessibilityProduct(), true )
    // product:  FacilityAccessibilityProduct3.INSTANCE
    product:  FacilityAccessibilityProduct4.INSTANCE,
    productType: EProductType.manufacturing,
    canCommentOnClusters: true,
    canEditClusters: false,

  },

  preview: {

    // fireworksEnabled: true,
    // reportGeneration2: true,
    clusterSupport: true,
  },



};
